import { AllegroDefaultValues } from './AllegroDefaultValues';
import { Services } from './Services';

export const Allegro = {
  clientEntityState: 4,
  tenantId: '',
  clientId: '',
  configurationName: '',
  password: '',
  courier: '',
  additionalServices: { services: [] },
  apiClientId: '',
  apiClientSecret: '',
  url: '',
  apiUrl: '',
  redirectUrl: '',
  token: '',
  refreshToken: '',
  code: '',
  fileFormat: 0,
  pageFormat: 1,
  referenceNumber: '',
  allegroDefaultValues: AllegroDefaultValues,
  services: Services,
};
